.ResourcesContentContainer {
    display:flex;
    flex-direction: column;
    width:100%;
    background-color: rgb(235, 235, 235);
    padding: 20px 0px;
    align-items: center;
    justify-content: center;
  }

  .ResourcesInnerContent {
    background-color: white;
    color: rgb(0, 0, 0);
    border-radius: 20px;
    text-align: left;
    width: 100%;
    max-width: 1200px;
  }

  .ResourcesIntroParagraph {
    margin: 15px;
    padding: 10px 10px;
    border-radius: 20px;
    background-color: #eee;
    border: 2px solid #12b49f;
  }

  .ResourcesPatientOnlyContainer h3{
    color: white;  
  }

  .ResourcesPatientOnlyContainer
  {
    padding: 10px;
    color: white;
    border-radius: 20px;
    background-color: #12b49f;
  }

  .patientCodeInput{
    margin:0px 10px;
    border-radius: 5px;
    border: 1px solid #cbcbd4;
    height: 34px;
    outline-color: #28464b;
    padding-left: 5px;
  }

  .patientCodeSubmit{
    cursor: pointer;
    outline:none;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #ffba49;
    color: white;
    font-size: 16pt;
    font-weight: lighter;
    border-radius: 10px;
    border: 0px;
    text-align: "center";
    height: 34px;
    vertical-align: middle;
  }
  
  .patientCodeSubmit:hover{
    background-color: #28464b;   
  }

  a{
    color: inherit;
    text-decoration: none;
  }

  @media only screen and (max-width: 690px) {
    .ResourcesContentContainer {
      padding: 10px 0px;
    }
    .ResourcesInnerContent {
      width: 100%;
    }
    
    .ResourcesIntroParagraph {
      padding: 10px 20px;
      margin: 15px;
    }

    .patientCodeInput{
      margin:20px 10px 20px 0px;
      border-radius: 5px;
      border: 1px solid #cbcbd4;
      height: 34px;
      outline-color: #28464b;
      padding-left: 5px;
    }
  }