@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(/src/fonts/raleway/Raleway-Regular.ttf) format('truetype');
}

body{
  background-color: #28464b;
}

.App {
  font-family: Raleway;
  display: flex;
  background-color: #28464b;
  flex-direction: column;
  text-align: center;
  min-height: 900px;
  padding: 0 0;
}

@media only screen and (max-width: 500px) {

  .App {
    background-color: #28464b;
    flex-direction: column;
    text-align: center;
    width: 100%;
    min-height: 900px;
    padding: 0 0;
  }
}
