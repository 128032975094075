.AboutContentContainer {
  display:flex;
  flex-direction: column;
  height:100%;
  color: rgb(0, 0, 0);
  background-color: rgb(235, 235, 235);
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
}

.AboutInnerContent {
  background-color: white;
  border-radius: 20px;
  text-align: left;
  width: 100%;
  max-width: 1200px;
}

.AboutIntroParagraph {
  margin: 15px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #eee;
  border: 2px solid #12b49f;
}

.AboutDiagramContainer
{
  display:flex;
  flex-direction: column;
  align-items: center;
}

.AboutDiagramContainer img
{
  width:80%;
  height:auto;
}

@media only screen and (max-width: 690px) {
  .AboutContentContainer {
    padding: 10px 0px;
  }
  .AboutInnerContent {
    width: 100%;
  }
  
  .AboutIntroParagraph {
    padding: 10px 20px;
    margin: 15px;
  }

  .AboutDiagramContainer img
  {
    width:100%;
    height:auto;
  }
}