.YouTubePlayer {
  position: relative;
  padding-bottom: 56.25% /* 16:9 */;
  padding-top: 25;
  height: 0;
}

.YouTubePlayerSidePadding {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0; 
}

.YouTubePlayer iframe
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.YouTubePlayerSidePadding iframe
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
