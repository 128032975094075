.Nav {
    position:fixed;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-end;
    top:0;
    right:0;
    margin: 10px 0px 10px 10px;
    min-width: 371px;
    height: 45px;
    line-height: 45px;
    padding: 1vh;
    color: white;
    border-radius: 20px 0 0 20px;
    font-weight: bolder;
    background-color: #ffba49;
    z-index: 1000;
  }

.menuLink{
  color:white;
  text-decoration: none;
}

.menuItem{
  padding-left: 15px;
  padding-right: 15px;
}

.menuItem:hover{
  cursor: pointer;
  color: #ffba49;
  background-color: white;
  border-radius: 20px;
}

.menuItem img{
  text-align: center;  
  height: 25px;
  width: 25px;
  padding-bottom: 1vh;
  filter: brightness(0) invert(1);
  vertical-align: middle;
}

.menuItem:hover img{
  height: 30px;
  width: 30px;
  filter: invert(90%) sepia(26%) saturate(2381%) hue-rotate(321deg) brightness(102%) contrast(101%);
}

@media only screen and (max-width: 690px) {
  .Nav {
    display: flex;
    position:fixed;
    top:0;
    width: 100%;
    min-width: 280px;
    padding: 3px 0;
    height: 45px;
    margin: 0px;
    font-size: 10pt;
    color: white;
    background-color: #ffba49;
    align-items:center;
    justify-content:center;
    border-radius: 0px;
    z-index: 1000;
  }
  .menuItem{
    padding-left: 15px;
    padding-right: 15px;
  }
}