
.LandingBanner {
    display: flex;
    padding: 0px;
    justify-content: center;
    background-color: #12b49f;
    max-height: 40vh;
  }

  .LandingBannerContentContainer
  {
    position:relative;
    align-items: center;
    color: white;
    max-height: 40vh;
    width: 100%;
    max-width: 1200px;
  }

  .MoniqueImgContainer {
    display:flex;
    height: 100%;
    overflow: hidden;
}

  .MoniqueImgContainer img.MoniqueImg{
    height: 100%;
    width: auto;
    vertical-align: middle;
}

  .HeaderTextContainer {
    position:absolute;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    font-size: 30pt;
    border-radius: 10px;
    background-color: #28464b;
    width: 60vw;
    max-width: 550px;
    padding: 10px;
    bottom: 10px;
    right: 10px;
  }

  .HeaderSlogan {
    margin-top: 10px;
    font-weight: lighter;
    font-size: 17pt;
  }

  .HeaderTextContainer img.NDLogo {
    width: 65px;
    padding: 10px;
    min-width: 65px; 
    filter: brightness(0) invert(1);
  }


  @media only screen and (max-width: 690px) {
    .LandingBanner {
      display: flex;
      position:relative;
      flex-direction: column;
      overflow:hidden;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 0;
      margin-top: 45px;
      max-height: 555px;
      color: white;
      background-color: #12b49f;
    }

    .MoniqueImgContainer img.MoniqueImg{
      display: flex;
      flex: 1;
      width: 100%;
      margin-top: 0;
    }
  
  .HeaderTextContainer {
    position:absolute;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    font-size: 17pt;
    border-radius: 10px;
    background-color: #28464b;
    width: 60vw;
    max-width: 550px;
    padding: 5px;
    bottom: 5px;
    right: 5px;
  }

  .HeaderSlogan {
    margin-top: 3px;
    font-weight: lighter;
    font-size: 10pt;
  }

  .HeaderTextContainer img.NDLogo {
    width: 25px;
    padding: 5px;
    min-width: 25px; 
    filter: brightness(0) invert(1);
  }
  
}