.ContactContentContainer {
    display:flex;
    flex-direction: column;
    background-color: rgb(235, 235, 235);
    padding: 20px 0px;
    align-items: center;
    justify-content: center;
  }

  .ContactInnerContent {
    flex: 1;
    background-color: white;
    color: rgb(0, 0, 0);
    border-radius: 20px;
    text-align: left;
    width: 100%;
    max-width: 1200px;
  }

  .ContactIntroParagraph {
    flex: 1;
    padding: 10px 10px;
    margin: 15px;
    border-radius: 20px;
    background-color: #eee;
    border: 2px solid #12b49f;
  }

  .ContactMapContainer
  {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

  h3{
    color: #28464b;
  }

  .contactButton{
    display:inline-block;
    cursor: pointer;
    outline:none;
    padding: 10px 10px;
    margin: 5px 5px;
    background-color: #12b49f;
    color: white;
    font-size: 16pt;
    font-weight: lighter;
    border-radius: 10px;
    border: 0px;
    text-align: "center";
  }

  .contactClipboardButton{
    display:inline-block;
    cursor: pointer;
    outline:none;
    padding: 2px 10px;
    margin: 10px 5px;
    background-color: #28464b;
    color: white;
    font-size: 10pt;
    font-weight: lighter;
    border-radius: 10px;
    border: 0px;
    text-align: "center";
  }


  .contactFormInputSingleLine
  {
    width: calc(100% - 10px);
  }

  .contactFormInputSingleLine input{
    border-radius: 5px;
    border: 1px solid #cbcbd4;
    height: 34px;
    width: 100%;
    outline-color: #28464b;
    margin: 10px 0px;
    padding-left: 5px;
  }
  
.contactSubmitContainer{
  display:flex;
  justify-content: center;
}

  .contactFormSubmit{
    display: flex;
    cursor: pointer;
    outline:none;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #ffba49;
    color: white;
    font-size: 16pt;
    font-weight: lighter;
    border-radius: 10px;
    border: 0px;
    text-align: "center";
    align-self: center;
    height: 34px;
  }

  .contactFormSubmit:hover{
    background-color: #28464b;
  }

  @media only screen and (max-width: 690px) {
    .ContactContentContainer {
      padding: 10px 0px;
    }
    .ContactInnerContent {
      width: 100%;
    }
    
    .ContactIntroParagraph {
      padding: 10px 20px;
      margin: 15px;
    }

    .contactButton{
      display:inline-block;
      cursor: pointer;
      outline:none;
      padding: 10px 10px;
      margin: 5px 5px;
      background-color: #12b49f;
      color: white;
      font-size: 14pt;
      font-weight: lighter;
      border-radius: 10px;
      border: 0px;
      text-align: "center";
    }
  
    .contactClipboardButton{
      display:inline-block;
      cursor: pointer;
      outline:none;
      padding: 5px 10px;
      margin: 10px 2px;
      background-color: #28464b;
      color: white;
      font-size: 10pt;
      font-weight: lighter;
      border-radius: 10px;
      border: 0px;
      text-align: "center";
    }
  }