.ContentContainer {
    display:flex;
    flex-direction: column;
    background-color: rgb(235, 235, 235);
    padding: 20px 0px;
    align-self: center;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .ContentInnerContent {
    background-color: white;
    color: rgb(0, 0, 0);
    padding: 0px;
    margin: 0px 10px;
    border-radius: 20px;
    text-align: left;
    width: 100%;
    max-width: 1200px;
  }

  .ContentIntroParagraph {
    padding: 10px 10px;
    margin: 15px;
    border-radius: 20px;
    background-color: #eee;
    border: 2px solid #12b49f;
  }

  .ContentIntroParagraph a {
    font-weight: bolder;
    color:#12b49f;
  }
  

  @media only screen and (max-width: 690px) {
    .ContentContainer {
      padding: 10px 0px;
    }
    .ContentInnerContent {
      width: 100%;
    }
    
    .ContentIntroParagraph {
      padding: 10px 20px;
      margin: 15px;
    }
  }