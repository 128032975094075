.Footer {
    flex: 1;
    min-height: 30px;
    padding: 1vh 1vh;
    color: white;
    font-size: 8pt;
    text-align: center;
    vertical-align: middle;
    background-color: #28464b;
  }

.FaltekLogo{
  padding: 0 5px;
  height: 18px;
  width: auto;
}
  
@media only screen and (max-width: 690px) {
  .Footer {
    flex: 0;
    height: 
  }
}